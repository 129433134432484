<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M60,100c0,-21.943 18.057,-40 40,-40l280,0c21.943,0 40,18.057 40,40l0,280c0,21.943 -18.057,40 -40,40l-280,0c-22.2,0 -40,-18 -40,-40l0,-280Zm320,0l-280,0l0,280l280,0l0,-280Zm-200,40l120,0c21.943,0 40,18.057 40,40l0,160l-40,0l0,-160l-40,0l0,140l-40,0l0,-140l-40,0l0,160l-40,0l0,-160c0,-21.943 18.057,-40 40,-40Z"
    />
  </svg>
</template>
