<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M240,60l180,80l0,280l-360,0l0,-280l180,-80Zm-20,180l0,-40l80,0l0,-40l-80,0c-21.943,0 -40,18.057 -40,40l0,40c0,22.077 17.923,40 40,40l40,0l0,40l-80,0l0,40l80,0c22.077,0 40,-17.923 40,-40l0,-40c0,-22.077 -17.923,-40 -40,-40l-40,0Z"
    />
  </svg>
</template>
