<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M356.2,357.636c-33.017,38.186 -81.807,62.364 -136.2,62.364c-99.345,0 -180,-80.655 -180,-180c0,-99.345 80.655,-180 180,-180c54.393,0 103.183,24.178 136.2,62.364c-54.846,11.054 -96.2,59.554 -96.2,117.636c0,58.082 41.354,106.582 96.2,117.636Zm23.8,-197.636c44.153,0 80,35.847 80,80c0,44.153 -35.847,80 -80,80c-44.153,0 -80,-35.847 -80,-80c0,-44.153 35.847,-80 80,-80Z"
    />
  </svg>
</template>
