// User State & Store
import { defineStore } from "pinia";
import Cookies from "js-cookie";
import type { User } from "@/types/payload-types";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: {} as User | null,
    loggedIn: false,
  }),

  getters: {
    isLoggedIn: (state) => state.loggedIn,
  },

  actions: {
    // 刷新token
    async refreshTokens() {
      const config = useRuntimeConfig();
      const payloadUrl = config.public.payloadUrl;
      const response = await fetch(`${payloadUrl}/api/users/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // this is required in order to send the refresh token cookie
      });
      if (response.status === 200) {
        return response.json();
      } else {
        this.removeTokens();
        return false;
      }
    },
    // 获取用户信息
    async fetchUser() {
      // 获取最新 auth_token
      const res = await this.refreshTokens();
      // 获取用户信息
      if (res) {
        this.user = res.user;
        this.loggedIn = true;
        Cookies.set("42kit_loggedIn", "true", {
          // Directus refresh token 有效期为刷新后的 7 天
          expires: new Date(res.exp * 1000),
        });
        return res.user;
      } else {
        this.user = null;
        this.loggedIn = false;
        this.removeTokens();
        return false;
      }
    },
    // 登出
    async logout() {
      const config = useRuntimeConfig();
      const payloadUrl = config.public.payloadUrl;
      const oidcUrl = config.public.oidcUrl;
      await fetch(`${payloadUrl}/api/users/logout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      });
      this.user = null;
      this.loggedIn = false;
      this.removeTokens();

      // OIDC 登出
      // encode the redirect URL
      const redirectUrl = encodeURIComponent(
        config.public.siteUrl + "/auth/signout",
      );
      window.location.href = `${oidcUrl}/oidc/session/end?client_id=ywmdh5pmxn7wltt066k81&post_logout_redirect_uri=${redirectUrl}`;
    },
    // 移除token
    removeTokens() {
      Cookies.remove("42kit_loggedIn");
    },
    // serverside 初始化用户
    async initUser(token: string) {
      const config = useRuntimeConfig();
      const backendUrl = config.public.backendUrl;
      if (token) {
        await fetch(`${backendUrl}/users/me`, {
          method: "GET",
          credentials: "include",
        })
          .then((response) => response.json())
          .then((data) => {
            this.user = data.data;
            this.loggedIn = true;
          });
      } else {
        this.user = null;
        this.loggedIn = false;
        this.removeTokens();
      }
    },
  },
});
