import { defineNuxtPlugin } from "#app";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as labsComponents from "vuetify/labs/components";
import "@mdi/font/css/materialdesignicons.css";
import type { ThemeDefinition } from "vuetify";
import customIconSet from "@/assets/customIconSet";

const light: ThemeDefinition = {
  dark: false,
  colors: {
    surface: "#fff",
    background: "#f4f5f6",
    primary: "#EB6888",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    text: "#000000",
    base: "#54595d",
  },
};

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    surface: "#22282c",
    background: "#3d4850",
    primary: "#EB6888",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    text: "#ffffff",
    base: "#b9bdc6",
  },
};

const aliasesCustom = {
  ...aliases,
  ...customIconSet,
};

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      defaultTheme: "light",
      cspNonce: "dQw4w9WgXcQ",
      themes: { light, dark },
    },
    icons: {
      defaultSet: "mdi",
      aliases: {
        ...aliasesCustom,
      },
      sets: {
        mdi,
      },
    },
    components: {
      ...labsComponents,
    },
    defaults: {
      VBtn: {
        rounded: "lg",
        color: "primary",
      },
      VCard: {
        rounded: "lg",
        elevation: "0",
      },
    },
  });
  nuxtApp.vueApp.use(vuetify);
});
