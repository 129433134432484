<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M369.981,339.507c-32.262,48.502 -87.415,80.493 -149.981,80.493c-99.345,0 -180,-80.655 -180,-180c0,-99.345 80.655,-180 180,-180c62.566,0 117.719,31.991 149.981,80.493c50.513,5.015 90.019,47.684 90.019,99.507c0,51.823 -39.506,94.492 -90.019,99.507Zm-9.981,-159.507c-33.115,0 -60,26.885 -60,60c0,33.115 26.885,60 60,60c33.115,-0 60,-26.885 60,-60c-0,-33.115 -26.885,-60 -60,-60Zm-35.753,-33.409c-25.64,-28.592 -62.858,-46.591 -104.247,-46.591c-77.268,0 -140,62.732 -140,140c0,77.268 62.732,140 140,140c41.389,-0 78.607,-17.999 104.247,-46.591c-37.556,-14.4 -64.247,-50.812 -64.247,-93.409c0,-42.597 26.691,-79.009 64.247,-93.409Z"
    />
  </svg>
</template>
