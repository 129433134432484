export default defineNuxtRouteMiddleware((_from) => {
  const config = useRuntimeConfig();
  const maintenanceMode = config.public.maintenanceMode;

  if (maintenanceMode) {
    if (_from.fullPath === "/maintenance") {
      return;
    }
    return navigateTo("/maintenance", { redirectCode: 302 });
  }
});
