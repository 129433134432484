import { useUserStore } from "@/store/SiteUser";

// Global Auth Middleware
export default defineNuxtRouteMiddleware(async (_from) => {
  if (!process.server) {
    const userStore = useUserStore();
    const cookieLoggedIn = useCookie("42kit_loggedIn");

    // 如果登录cookie存在，尝试获取用户信息
    if (cookieLoggedIn.value) {
      const user = await userStore.fetchUser();

      // 如果用户信息存在
      if (user) {
        // 如果用户信息不完整，跳转到设置页面
        if (!user.username || !user.name) {
          // 如果当前页面不是设置页面，跳转到设置页面
          if (window) {
            if (!window.location.pathname.startsWith("/auth")) {
              return (window.location.href = "/auth/setup");
            }
          }
        }
      }
    }
  }
});
