<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M240,60c99.345,0 180,80.655 180,180c0,99.345 -80.655,180 -180,180c-99.345,0 -180,-80.655 -180,-180c0,-99.345 80.655,-180 180,-180Zm0,40c-77.268,0 -140,62.732 -140,140c0,77.268 62.732,140 140,140c77.268,0 140,-62.732 140,-140c0,-77.268 -62.732,-140 -140,-140Z"
    />
  </svg>
</template>
