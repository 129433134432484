import HangerS from "@/assets/icons/HangerS.vue";
import HangerM from "@/assets/icons/HangerM.vue";
import HangerL from "@/assets/icons/HangerL.vue";
import HangerXL from "@/assets/icons/HangerXL.vue";
import DockingPortS from "@/assets/icons/DockingPortS.vue";
import DockingPortL from "@/assets/icons/DockingPortL.vue";
import LandingPadS from "@/assets/icons/LandingPadS.vue";
import LandingPadM from "@/assets/icons/LandingPadM.vue";
import LandingPadL from "@/assets/icons/LandingPadL.vue";
import LandingPadXL from "@/assets/icons/LandingPadXL.vue";
import ArmisticeZone from "@/assets/icons/ArmisticeZone.vue";
import QTMarkerMoon from "@/assets/icons/QTMarkerMoon.vue";
import QTMarkerPlanet from "@/assets/icons/QTMarkerPlanet.vue";
import QTMarkerRhombus from "@/assets/icons/QTMarkerRhombus.vue";
import Star from "@/assets/icons/Star.vue";
import Nebula from "@/assets/icons/Nebula.vue";
import PlanetoidBelt from "@/assets/icons/PlanetoidBelt.vue";
import Moon from "@/assets/icons/Moon.vue";
import JumpPoint from "@/assets/icons/JumpPoint.vue";
import LagrangePoint from "@/assets/icons/LagrangePoint.vue";
import CommArray from "@/assets/icons/CommArray.vue";
import SpaceStation from "@/assets/icons/SpaceStation.vue";

const customIconSet = {
  HangerS,
  HangerM,
  HangerL,
  HangerXL,
  DockingPortS,
  DockingPortL,
  LandingPadS,
  LandingPadM,
  LandingPadL,
  LandingPadXL,
  ArmisticeZone,
  QTMarkerMoon,
  QTMarkerPlanet,
  QTMarkerRhombus,
  Star,
  Nebula,
  PlanetoidBelt,
  Moon,
  JumpPoint,
  LagrangePoint,
  CommArray,
  SpaceStation,
};

export default customIconSet;
