<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M76.883,204.413c-3.958,-27.407 5.942,-61.184 30.31,-87.285c24.368,-26.102 57.387,-38.295 85,-36.226c19.297,-24.789 51.215,-42.557 83.635,-43.13c33.156,-0.586 59.703,16.031 72.657,40.038c29.484,-2.245 58.749,10.96 75,37.486c16.034,26.173 15.85,60.644 2.248,88.95c15.438,21.548 20.137,53.946 7.371,86.559c-12.64,32.289 -39.773,57.214 -67.823,67.274c-8.113,28.675 -31.118,57.454 -62.463,72.278c-31.661,14.974 -64.305,12.508 -86.861,-1.416c-27.307,15.512 -61.684,18.06 -88.895,3.859c-27.579,-14.393 -42.76,-42.683 -42.543,-72.251c-24.838,-11.277 -43.238,-36.622 -44.927,-69.74c-1.652,-32.382 13.885,-65.444 37.291,-86.396Zm33.478,24.543c-18.63,12.254 -32.022,36.281 -30.821,59.815c1.021,20.007 13.247,35.25 29.748,37.819l21.249,3.308l-4.838,20.953c-4.195,18.171 2.91,37.637 19.87,46.488c18.358,9.581 42.241,4.756 58.698,-8.694l14.588,-11.922l12.771,13.849c11.411,12.373 33.299,13.458 54.09,3.625c23.26,-11.001 40.212,-33.349 42.544,-54.332l1.647,-14.811l14.663,-2.659c20.773,-3.766 41.906,-22.21 51.286,-46.17c8.383,-21.417 5.8,-43.179 -7.327,-53.714l-14.693,-11.792l10.893,-15.37c12.29,-17.342 15.465,-41.5 4.648,-59.157c-9.994,-16.312 -29.902,-22.066 -47.742,-16.634l-20.572,6.264l-4.757,-20.972c-3.695,-16.286 -19.741,-27.438 -39.771,-27.084c-23.561,0.416 -46.613,15.424 -57.56,34.851l-7.855,13.94l-15.323,-4.604c-17.929,-5.386 -41.918,3.783 -59.366,22.472c-17.447,18.688 -24.949,43.25 -18.346,60.767l5.644,14.972l-13.368,8.792Z"
    />
  </svg>
</template>
