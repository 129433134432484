<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M240,40.051c110.355,0 199.949,89.594 199.949,199.949c-0,110.355 -89.594,199.949 -199.949,199.949c-110.355,-0 -199.949,-89.594 -199.949,-199.949c0,-110.355 89.594,-199.949 199.949,-199.949Zm-130.049,90.507c-24.925,29.581 -39.951,67.77 -39.951,109.442c0,93.826 76.174,170 170,170c41.672,-0 79.861,-15.026 109.442,-39.951l-79.746,-79.745l20,-20l79.796,79.795c25.258,-29.671 40.508,-68.118 40.508,-110.099c0,-93.826 -76.174,-170 -170,-170c-41.981,-0 -80.428,15.25 -110.099,40.508l79.795,79.796l-20,20l-79.745,-79.746Zm180.049,89.442l-20,20l-10,20l-90,90l-20,-0l-20,-20l0,-20l90,-90l20,-10l20,-20l30,30Zm-20,-40l20,-20c11.118,-11.118 60,-30 60,-30c0,-0 -18.882,48.882 -30,60l-20,20l-30,-30Z"
    />
  </svg>
</template>
