<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M146.831,339.982c-10.999,36.58 -27.897,60.018 -46.831,60.018c-33.115,0 -60,-71.694 -60,-160c0,-88.306 26.885,-160 60,-160c18.934,0 35.832,23.438 46.831,60.018c19.997,23.046 51.116,47.482 93.169,47.482c42.053,0 73.172,-24.436 93.169,-47.482c10.999,-36.58 27.897,-60.018 46.831,-60.018c33.115,0 60,71.694 60,160c0,88.306 -26.885,160 -60,160c-18.934,0 -35.832,-23.438 -46.831,-60.018c-19.997,-23.046 -51.116,-47.482 -93.169,-47.482c-42.053,0 -73.172,24.436 -93.169,47.482Zm176.176,-150.101c-22.256,15.944 -50.012,27.619 -83.007,27.619c-32.995,0 -60.751,-11.675 -83.007,-27.619c1.951,15.768 3.007,32.617 3.007,50.119c-0,17.502 -1.056,34.351 -3.007,50.119c22.256,-15.944 50.012,-27.619 83.007,-27.619c32.995,0 60.751,11.675 83.007,27.619c-1.951,-15.768 -3.007,-32.617 -3.007,-50.119c-0,-17.502 1.056,-34.351 3.007,-50.119Zm-223.007,-49.881c-18.397,-0 -33.333,44.808 -33.333,100c-0,55.192 14.936,100 33.333,100c18.397,0 33.333,-44.808 33.333,-100c0,-55.192 -14.936,-100 -33.333,-100Zm280,0c-18.397,0 -33.333,44.808 -33.333,100c-0,55.192 14.936,100 33.333,100c18.397,0 33.333,-44.808 33.333,-100c0,-55.192 -14.936,-100 -33.333,-100Z"
    />
  </svg>
</template>
