<template>
  <div>
    <NuxtLoadingIndicator color="#eb6888" />
    <NuxtLayout>
      <NuxtPage />
      <span v-if="$colorMode.preference === 'system'"
        >(<i>{{ $colorMode.value }}</i> mode detected)</span
      >
    </NuxtLayout>
    <ClientOnly>
      <NuxtSnackbar />
    </ClientOnly>
  </div>
</template>
<script setup>
// New global theme switcher using store
import { useTheme } from "vuetify";
const colorMode = useColorMode();

// Page Title
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} · 42KIT`
      : "42KIT - A Wandering Citizen Project";
  },
});
// Theme Setting
const cookie = useCookie("citizencat-theme");
const theme = useTheme();
const themeFromCookie = cookie.value;
if (themeFromCookie) {
  theme.global.name.value = themeFromCookie;
  colorMode.value = themeFromCookie === "dark" ? "dark" : "light";
  colorMode.preference = themeFromCookie === "dark" ? "dark" : "light";
  // set the cookie to the value
  cookie.value = themeFromCookie;
} else if (!themeFromCookie && colorMode.value === "system") {
  theme.global.name.value = "light";
  colorMode.value = "light";
  colorMode.preference = "light";
  cookie.value = "light";
}
</script>
