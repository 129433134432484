<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M290,350c16.557,0 30,13.443 30,30c0,16.557 -13.443,30 -30,30c-16.557,0 -30,-13.443 -30,-30c0,-16.557 13.443,-30 30,-30Zm-247.926,-159.374c21.334,-72.576 88.484,-125.626 167.926,-125.626c13.854,-0 27.335,1.613 40.264,4.662c-6.083,7.952 -9.834,17.779 -10.229,28.458c-9.692,-2.045 -19.739,-3.12 -30.035,-3.12c-64.373,-0 -119.004,42.039 -137.915,100.137c-6.661,-3.289 -14.159,-5.137 -22.085,-5.137c-2.697,-0 -5.344,0.214 -7.926,0.626Zm294.221,-71.716c30.16,31.447 48.705,74.12 48.705,121.09c0,46.97 -18.545,89.643 -48.705,121.09c-3.923,-9.583 -10.737,-17.682 -19.354,-23.209c23.631,-25.801 38.059,-60.17 38.059,-97.881c0,-37.711 -14.428,-72.08 -38.059,-97.881c8.617,-5.527 15.431,-13.626 19.354,-23.209Zm-86.031,291.428c-12.929,3.049 -26.41,4.662 -40.264,4.662c-79.442,0 -146.592,-53.05 -167.926,-125.626c2.582,0.412 5.229,0.626 7.926,0.626c7.926,0 15.424,-1.848 22.085,-5.137c18.911,58.098 73.542,100.137 137.915,100.137c10.296,-0 20.343,-1.075 30.035,-3.12c0.395,10.679 4.146,20.506 10.229,28.458Zm-152.557,-185.338l103.633,-0l52.338,-90.653c6.906,7.3 15.985,12.522 26.197,14.625l-43.894,76.028l26.312,-0c-1.49,4.736 -2.293,9.775 -2.293,15c0,5.225 0.803,10.264 2.293,15l-26.312,-0l43.894,76.028c-10.212,2.103 -19.291,7.325 -26.197,14.625l-52.338,-90.653l-103.633,-0c1.49,-4.736 2.293,-9.775 2.293,-15c0,-5.225 -0.803,-10.264 -2.293,-15Zm192.293,-155c16.557,-0 30,13.443 30,30c0,16.557 -13.443,30 -30,30c-16.557,-0 -30,-13.443 -30,-30c0,-16.557 13.443,-30 30,-30Zm140,140c16.557,-0 30,13.443 30,30c0,16.557 -13.443,30 -30,30c-16.557,-0 -30,-13.443 -30,-30c0,-16.557 13.443,-30 30,-30Zm-120,-0c16.557,-0 30,13.443 30,30c0,16.557 -13.443,30 -30,30c-16.557,-0 -30,-13.443 -30,-30c0,-16.557 13.443,-30 30,-30Zm-260,-0c16.557,-0 30,13.443 30,30c0,16.557 -13.443,30 -30,30c-16.557,-0 -30,-13.443 -30,-30c0,-16.557 13.443,-30 30,-30Z"
    />
  </svg>
</template>
