<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M240,40c110.383,0 200,89.617 200,200c0,110.383 -89.617,200 -200,200c-110.383,0 -200,-89.617 -200,-200c0,-110.383 89.617,-200 200,-200Zm0,20c-99.345,0 -180,80.655 -180,180c0,99.345 80.655,180 180,180c99.345,0 180,-80.655 180,-180c0,-99.345 -80.655,-180 -180,-180Zm0,20c88.306,0 160,71.694 160,160c0,88.306 -71.694,160 -160,160c-88.306,0 -160,-71.694 -160,-160c0,-88.306 71.694,-160 160,-160Zm0,40c-66.23,0 -120,53.77 -120,120c0,66.23 53.77,120 120,120c66.23,0 120,-53.77 120,-120c0,-66.23 -53.77,-120 -120,-120Z"
    />
  </svg>
</template>
