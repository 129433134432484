<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M253.3,346.61l0,26.64l-53.21,26.64l0,39.97l-90.09,0.14l-70,0l0,-160l160.09,0l0,39.96l53.21,26.65Zm116.7,-66.61l70,0l0,160l-70,0l0,-0.07l-116.7,0l0,-26.64l53.22,-26.65l0,-53.28l-53.22,-26.65l0,-26.64l116.7,0.2l0,-0.27Zm-150,-140l40,-0c22.077,-0 40,17.923 40,40l0,40c0,22.077 -17.923,40 -40,40l-80,-0l0,-40l80,-0l0,-40l-40,-0c-22.077,-0 -40,-17.923 -40,-40l0,-40c0,-21.943 18.057,-40 40,-40l80,-0l0,40l-80,-0l0,40Z"
    />
  </svg>
</template>
