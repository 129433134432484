<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M240,70.294l169.706,169.706l-169.706,169.706l-169.706,-169.706l169.706,-169.706Zm0,56.569l-113.137,113.137l113.137,113.137l113.137,-113.137l-113.137,-113.137Z"
    />
  </svg>
</template>
