<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M91.26,208.975l-72.565,-21.343l-8.695,-43.474l65.769,19.343c-5.918,-28.827 -2.232,-52.844 12.684,-67.759c31.917,-31.918 105.52,-12.415 178.731,42.596l-26.341,26.341c-34.655,-22.031 -65.53,-29.325 -79.02,-15.834c-9.403,9.402 -8.71,27.248 0.031,48.932l84.774,24.353l39.24,-39.24l-7.904,-7.905l142.282,-142.282l47.427,47.428l-142.282,142.281l-7.904,-7.904l-21.735,21.734l49.125,14.112l17.161,-17.161c4.597,6.119 8.948,12.241 13.04,18.341l78.367,23.048l8.695,43.475l-59.457,-17.487c21.531,47.713 23.908,89.397 1.951,111.354c-39.372,39.372 -142.176,0.5 -229.429,-86.753c-37.957,-37.956 -66.757,-78.855 -83.945,-116.196Zm84.651,16.102c11.026,17.884 26.07,36.887 44.286,55.103c52.352,52.351 111.2,78.508 131.334,58.374c11.674,-11.674 7.786,-36.362 -7.746,-65.252l-66.103,-18.989l-25.341,25.34c-5.473,-4.779 -10.937,-9.884 -16.335,-15.282c-5.398,-5.398 -10.504,-10.863 -15.283,-16.336l7.834,-7.834l-52.646,-15.124Zm-0.364,131.37l-63.578,63.579l-31.619,-31.618l63.579,-63.579c4.978,5.431 10.135,10.82 15.467,16.152c5.331,5.331 10.721,10.488 16.151,15.466Z"
    />
  </svg>
</template>
