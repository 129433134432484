import validate from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/middleware/auth.global.ts";
import maintenance_45global from "/app/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-only": () => import("/app/middleware/authOnly.ts"),
  "visitor-only": () => import("/app/middleware/visitorOnly.ts")
}