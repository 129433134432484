import { default as setupqSc9RPyMGEMeta } from "/app/pages/auth/setup.vue?macro=true";
import { default as signinFK2lTWFqnvMeta } from "/app/pages/auth/signin.vue?macro=true";
import { default as signoutThcUhmG1tRMeta } from "/app/pages/auth/signout.vue?macro=true";
import { default as indexnqGPcl8Ho6Meta } from "/app/pages/events/[id]/index.vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as basiclv94klbVEVMeta } from "/app/pages/events/manage/[id]/basic.vue?macro=true";
import { default as contentUWhLl5L5PEMeta } from "/app/pages/events/manage/[id]/content.vue?macro=true";
import { default as indexwZbD9kujKxMeta } from "/app/pages/events/manage/[id]/index.vue?macro=true";
import { default as participantslSn59SdytEMeta } from "/app/pages/events/manage/[id]/participants.vue?macro=true";
import { default as recordsdNrDpkHj5uMeta } from "/app/pages/events/manage/[id]/records.vue?macro=true";
import { default as newWwud6IcH7FMeta } from "/app/pages/events/new.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as index0iTx9rYtb7Meta } from "/app/pages/udb/index.vue?macro=true";
import { default as editAW6PWyvmzUMeta } from "/app/pages/udb/locations/[slug]/edit.vue?macro=true";
import { default as indexwQUyXBq3piMeta } from "/app/pages/udb/locations/[slug]/index.vue?macro=true";
import { default as indexQmZIn62finMeta } from "/app/pages/udb/locations/index.vue?macro=true";
import { default as indexZFyHGkjvhQMeta } from "/app/pages/udb/systems/index.vue?macro=true";
export default [
  {
    name: setupqSc9RPyMGEMeta?.name ?? "auth-setup",
    path: setupqSc9RPyMGEMeta?.path ?? "/auth/setup",
    meta: setupqSc9RPyMGEMeta || {},
    alias: setupqSc9RPyMGEMeta?.alias || [],
    redirect: setupqSc9RPyMGEMeta?.redirect,
    component: () => import("/app/pages/auth/setup.vue").then(m => m.default || m)
  },
  {
    name: signinFK2lTWFqnvMeta?.name ?? "auth-signin",
    path: signinFK2lTWFqnvMeta?.path ?? "/auth/signin",
    meta: signinFK2lTWFqnvMeta || {},
    alias: signinFK2lTWFqnvMeta?.alias || [],
    redirect: signinFK2lTWFqnvMeta?.redirect,
    component: () => import("/app/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: signoutThcUhmG1tRMeta?.name ?? "auth-signout",
    path: signoutThcUhmG1tRMeta?.path ?? "/auth/signout",
    meta: signoutThcUhmG1tRMeta || {},
    alias: signoutThcUhmG1tRMeta?.alias || [],
    redirect: signoutThcUhmG1tRMeta?.redirect,
    component: () => import("/app/pages/auth/signout.vue").then(m => m.default || m)
  },
  {
    name: indexnqGPcl8Ho6Meta?.name ?? "events-id",
    path: indexnqGPcl8Ho6Meta?.path ?? "/events/:id()",
    meta: indexnqGPcl8Ho6Meta || {},
    alias: indexnqGPcl8Ho6Meta?.alias || [],
    redirect: indexnqGPcl8Ho6Meta?.redirect,
    component: () => import("/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWgIHVzns1AMeta?.name ?? "events",
    path: indexWgIHVzns1AMeta?.path ?? "/events",
    meta: indexWgIHVzns1AMeta || {},
    alias: indexWgIHVzns1AMeta?.alias || [],
    redirect: indexWgIHVzns1AMeta?.redirect,
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: basiclv94klbVEVMeta?.name ?? "events-manage-id-basic",
    path: basiclv94klbVEVMeta?.path ?? "/events/manage/:id()/basic",
    meta: basiclv94klbVEVMeta || {},
    alias: basiclv94klbVEVMeta?.alias || [],
    redirect: basiclv94klbVEVMeta?.redirect,
    component: () => import("/app/pages/events/manage/[id]/basic.vue").then(m => m.default || m)
  },
  {
    name: contentUWhLl5L5PEMeta?.name ?? "events-manage-id-content",
    path: contentUWhLl5L5PEMeta?.path ?? "/events/manage/:id()/content",
    meta: contentUWhLl5L5PEMeta || {},
    alias: contentUWhLl5L5PEMeta?.alias || [],
    redirect: contentUWhLl5L5PEMeta?.redirect,
    component: () => import("/app/pages/events/manage/[id]/content.vue").then(m => m.default || m)
  },
  {
    name: indexwZbD9kujKxMeta?.name ?? "events-manage-id",
    path: indexwZbD9kujKxMeta?.path ?? "/events/manage/:id()",
    meta: indexwZbD9kujKxMeta || {},
    alias: indexwZbD9kujKxMeta?.alias || [],
    redirect: indexwZbD9kujKxMeta?.redirect,
    component: () => import("/app/pages/events/manage/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: participantslSn59SdytEMeta?.name ?? "events-manage-id-participants",
    path: participantslSn59SdytEMeta?.path ?? "/events/manage/:id()/participants",
    meta: participantslSn59SdytEMeta || {},
    alias: participantslSn59SdytEMeta?.alias || [],
    redirect: participantslSn59SdytEMeta?.redirect,
    component: () => import("/app/pages/events/manage/[id]/participants.vue").then(m => m.default || m)
  },
  {
    name: recordsdNrDpkHj5uMeta?.name ?? "events-manage-id-records",
    path: recordsdNrDpkHj5uMeta?.path ?? "/events/manage/:id()/records",
    meta: recordsdNrDpkHj5uMeta || {},
    alias: recordsdNrDpkHj5uMeta?.alias || [],
    redirect: recordsdNrDpkHj5uMeta?.redirect,
    component: () => import("/app/pages/events/manage/[id]/records.vue").then(m => m.default || m)
  },
  {
    name: newWwud6IcH7FMeta?.name ?? "events-new",
    path: newWwud6IcH7FMeta?.path ?? "/events/new",
    meta: newWwud6IcH7FMeta || {},
    alias: newWwud6IcH7FMeta?.alias || [],
    redirect: newWwud6IcH7FMeta?.redirect,
    component: () => import("/app/pages/events/new.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenanceM1L3mPagESMeta?.name ?? "maintenance",
    path: maintenanceM1L3mPagESMeta?.path ?? "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    alias: maintenanceM1L3mPagESMeta?.alias || [],
    redirect: maintenanceM1L3mPagESMeta?.redirect,
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search",
    path: searchfcnTL5fl5XMeta?.path ?? "/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: index0iTx9rYtb7Meta?.name ?? "udb",
    path: index0iTx9rYtb7Meta?.path ?? "/udb",
    meta: index0iTx9rYtb7Meta || {},
    alias: index0iTx9rYtb7Meta?.alias || [],
    redirect: index0iTx9rYtb7Meta?.redirect,
    component: () => import("/app/pages/udb/index.vue").then(m => m.default || m)
  },
  {
    name: editAW6PWyvmzUMeta?.name ?? "udb-locations-slug-edit",
    path: editAW6PWyvmzUMeta?.path ?? "/udb/locations/:slug()/edit",
    meta: editAW6PWyvmzUMeta || {},
    alias: editAW6PWyvmzUMeta?.alias || [],
    redirect: editAW6PWyvmzUMeta?.redirect,
    component: () => import("/app/pages/udb/locations/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexwQUyXBq3piMeta?.name ?? "udb-locations-slug",
    path: indexwQUyXBq3piMeta?.path ?? "/udb/locations/:slug()",
    meta: indexwQUyXBq3piMeta || {},
    alias: indexwQUyXBq3piMeta?.alias || [],
    redirect: indexwQUyXBq3piMeta?.redirect,
    component: () => import("/app/pages/udb/locations/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmZIn62finMeta?.name ?? "udb-locations",
    path: indexQmZIn62finMeta?.path ?? "/udb/locations",
    meta: indexQmZIn62finMeta || {},
    alias: indexQmZIn62finMeta?.alias || [],
    redirect: indexQmZIn62finMeta?.redirect,
    component: () => import("/app/pages/udb/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexZFyHGkjvhQMeta?.name ?? "udb-systems",
    path: indexZFyHGkjvhQMeta?.path ?? "/udb/systems",
    meta: indexZFyHGkjvhQMeta || {},
    alias: indexZFyHGkjvhQMeta?.alias || [],
    redirect: indexZFyHGkjvhQMeta?.redirect,
    component: () => import("/app/pages/udb/systems/index.vue").then(m => m.default || m)
  }
]