<template>
  <v-container
    v-if="error"
    class="d-flex text-center"
    fluid
    style="height: 100vh"
  >
    <v-row class="align-center justify-center">
      <v-col>
        <v-card class="pa-5" flat>
          <v-row dense>
            <v-col cols="12" class="d-flex justify-center">
              <v-icon size="10rem" color="primary">
                {{
                  error.statusCode === 404
                    ? "mdi-help-rhombus-outline"
                    : "mdi-alert-octagon-outline"
                }}
              </v-icon>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <h1>
                {{ errorTitle }}
              </h1>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <h4 v-dompurify-html="error.message" />
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mt-3"
                variant="outlined"
                @click="handleError"
              >
                返回首页
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
let errorTitle = "404 未找到页面";

// customise 404 message from script section
const error = useError();
if (error.value.statusCode === 404) {
  errorTitle = "404 未找到页面";
  error.value.message = "哎呀! 页面不见了，地址可能错误或者页面已经被删除😔。";
} else {
  errorTitle = "500 我们好像崩了页...";
  error.value.message =
    error.value.message ||
    "42KIT 似乎遇到了一些问题❗<br>错误信息已发送，问题将会被尽快被定位";
}

useHead({
  title: errorTitle + " - 42KIT",
});

// clear error and redirect to home page
const handleError = () => {
  error.value = null;
  window.location.href = "/";
};
</script>
