<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M71,381.8l35.8,-36l28.4,28.4l-36,35.8l-28.2,-28.2Zm169,-261.8c66.2,0 120,53.6 120,120c0,66.2 -53.8,120 -120,120c-66.2,0 -120,-53.8 -120,-120c0,-66.2 53.8,-120 120,-120Zm0,40c-44.153,0 -80,35.847 -80,80c0,44.153 35.847,80 80,80c44.153,0 80,-35.847 80,-80c0,-44.153 -35.847,-80 -80,-80Zm160,100l0,-40l60,0l0,40l-60,0Zm-55.2,114.2l28.4,-28.4l35.8,36l-28.2,28.2l-36,-35.8Zm64.2,-274.2l-35.8,36.2l-28.4,-28.4l36,-35.8l28.2,28Zm-149,-80l0,60l-40,0l0,-60l40,0Zm-124.8,87.8l-28.4,28.4l-35.8,-36.2l28.2,-28l36,35.8Zm-115.2,152.2l0,-40l60,0l0,40l-60,0Zm240,140l0,60l-40,0l0,-60l40,0Z"
      style="fill-rule: nonzero"
    />
  </svg>
</template>
