<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M240,60l180,80l0,280l-360,0l0,-280l180,-80Zm10,100l-0,200l120,0l0,-40l-80,0l0,-160l-40,0Zm-140,0l40,100l-40,100l40,0l20,-50l20,50l40,0l-40,-100l40,-100l-40,0l-20,50l-20,-50l-40,0Z"
    />
  </svg>
</template>
