<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 480 480"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
    fill="currentColor"
  >
    <path
      d="M40,240c0,-110.383 89.617,-200 200,-200l0,30c-93.826,0 -170,76.174 -170,170l-30,0Zm60,0c0,-77.268 62.732,-140 140,-140l0,30c-60.711,0 -110,49.289 -110,110l-30,0Zm60,0c0,-44.153 35.847,-80 80,-80l0,30c-27.596,0 -50,22.404 -50,50l-30,0Zm95,125l25,25l-20,20l-70,-70l20,-20l25,25l25,-25l-50,-50l60,-60l50,50l25,-25l-25,-25l20,-20l70,70l-20,20l-25,-25l-25,25l60,60l0,20l-40,40l-20,0l-60,-60l-25,25Zm95,-185l20,-20l70,70l-20,20l-70,-70Zm-190,190l20,-20l70,70l-20,20l-70,-70Z"
    />
  </svg>
</template>
