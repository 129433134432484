import revive_payload_client_5IFMytHKd7 from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gB3lrm8wDL from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jUmg2oi1nG from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_l6N9os4Vij from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_SzbdUi0NW0 from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_7adySTJDq3 from "/app/node_modules/.pnpm/nuxt-snackbar@1.0.4_vue@3.4.23/node_modules/nuxt-snackbar/dist/runtime/plugin.mjs";
import plugin_vue3_9GLFJzpj1B from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.3.2_vue@3.4.23/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_hYjBqYJDFX from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_UxkoCLpdG7 from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_jwt-decode@4.0.0_nuxt@3.11.2_vite@4.5.0_vue@3.4.23/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_MFZGYFDD9F from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_jwt-decode@4.0.0_nuxt@3.11.2_vite@4.5.0_vue@3.4.23/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_MbVnUgAxJ4 from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_jwt-decode@4.0.0_nuxt@3.11.2_vite@4.5.0_vue@3.4.23/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_r2zA6Jmq71 from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_1kOXmUjcTR from "/app/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.13_react-dom@18.2.0_react@18.2.0_typescript@5.3.2_vue@3.4.23/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import composition_IXCSAYWnK9 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_vue@3.4.23/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_CFk8qMVvON from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_vue@3.4.23/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_tRh7bcFx4v from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.10.0_@unocss+reset@0.59.3_eslint@8.54.0_floating-vue@5.2.2_jwt-dec_jj74z5js5heuxidod7wuv5fshe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directus_MCKsK3zbCM from "/app/modules/directus/runtime/plugins/directus.ts";
import dompurify_WZ68Rfx4Ds from "/app/plugins/dompurify.js";
import instantSearch_JRki1lOqBW from "/app/plugins/instantSearch.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_5IFMytHKd7,
  unhead_gB3lrm8wDL,
  router_jUmg2oi1nG,
  payload_client_l6N9os4Vij,
  check_outdated_build_client_SzbdUi0NW0,
  plugin_7adySTJDq3,
  plugin_vue3_9GLFJzpj1B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hYjBqYJDFX,
  slideovers_UxkoCLpdG7,
  modals_MFZGYFDD9F,
  colors_MbVnUgAxJ4,
  plugin_client_r2zA6Jmq71,
  plugin_1kOXmUjcTR,
  composition_IXCSAYWnK9,
  i18n_CFk8qMVvON,
  chunk_reload_client_tRh7bcFx4v,
  directus_MCKsK3zbCM,
  dompurify_WZ68Rfx4Ds,
  instantSearch_JRki1lOqBW,
  sentry_client_shVUlIjFLk,
  vuetify_7h9QAQEssH
]